const fetch = require("isomorphic-fetch");
//const history = require("react-router");

class ServiceAdapter {
  createDefaultHeaders = async () => {
    const xsrfToken = ("; " + document.cookie)
      .split("; XSRF-TOKEN=")
      .pop()
      .split(";")
      .shift();

    const JSESSIONID = ("; " + document.cookie)
      .split("; JSESSIONID=")
      .pop()
      .split(";")
      .shift();


    return {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": xsrfToken,
      "clientid": JSESSIONID
    };
  }

  addEndpointToUrl = (url: string): string => {
    return `${process.env.API_ENDPOINT}${url}`;
  }

  loginHasBeenstarted: boolean = false;

  isLoggedIn = (response): boolean => {
    if (response.status == 401) {
      if (this.loginHasBeenstarted) {
        return false;
      }
      this.loginHasBeenstarted = true;
      window.location.href = "/relogin";
      return false;
    }
    return true;
  };

  handleLogin = (resp : Promise<Response>) : Promise<Response> => {
    if (!this.isLoggedIn(resp)) {
      return Promise.reject('user not logged in');
    }
    return resp;
  };

  async sendGetRequest(url: string): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders();
    return fetch(this.addEndpointToUrl(url), {
      method: "GET",
      credentials: "same-origin",
      headers: defaultHeaders
    }).then(this.handleLogin);
  }

  async sendDeleteRequest(url: string): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders();
    return fetch(this.addEndpointToUrl(url), {
      method: "DELETE",
      credentials: "same-origin",
      headers: defaultHeaders
    }).then(this.handleLogin);
  }

  async sendPostRequest(url: string, body: any): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders();
    return fetch(this.addEndpointToUrl(url), {
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify(body),
      headers: defaultHeaders
    }).then(this.handleLogin);
  }

  async sendPatchRequest(url: string, body: any): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders();
    return fetch(this.addEndpointToUrl(url), {
      method: "PATCH",
      credentials: "same-origin",
      body: JSON.stringify(body),
      headers: defaultHeaders
    }).then(this.handleLogin);
  }
}

export const serviceAdapter = new ServiceAdapter();

import { observable, action, runInAction } from "mobx";
import { serviceAdapter } from "../service/serviceAdapter";

export interface IAttributes {
  [name: string]: string
}

export class MainViewStore {
  constructor() {
    this.attributes = {};
    this.isLoading = true;
    this.isLoadingv1 = true;
  }

  @observable
  isLoading: boolean;

  @observable
  isLoadingv1: boolean;

  @observable
  access_token: string;

  @observable
  id_token: string;

  @observable
  refresh_token: string;

  @observable
  user_data: string;

  @observable
  user_data_v1: string;

  @observable
  attributes: IAttributes

  b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  @action
  fetchUserData = async () => {
    this.isLoading = true;
    console.log('fetch');
    try {
      let response = await serviceAdapter.sendGetRequest("/api/info");

      if (response.status >= 400) {
        throw new Error("Bad response from server");
      }
      const data = await response.json();

      // in strict mode, you have to make state changes inside actions
      runInAction(() => {
        this.access_token = data.accessToken;
        this.id_token = data.idToken;
        this.refresh_token = data.refreshToken;
        this.attributes = data.attributes;
        if (data.accountData && data.accountData.length > 0 && data.accountData != 'N/A') {
          this.user_data = this.b64_to_utf8(data.accountData);
        }
        console.log('user data: ', this.user_data);
        this.isLoading = false;
      });
    } catch (err) {
      console.error(err);
      this.isLoading = true;
    }
  };

  @action
  fetchUserDataV1 = async () => {
    this.isLoadingv1 = true;
    console.log('fetch');
    try {
      let response = await serviceAdapter.sendGetRequest("/api/infov1");

      if (response.status >= 400) {
        throw new Error("Bad response from server");
      }
      const data = await response.json();

      // in strict mode, you have to make state changes inside actions
      runInAction(() => {
        if (data.accountData && data.accountData.length > 0 && data.accountData != 'N/A') {
          this.user_data_v1 = this.b64_to_utf8(data.accountData);
        }
        this.isLoadingv1 = false;
      });
    } catch (err) {
      console.error(err);
      this.isLoadingv1 = true;
    }
  };


}

export const mainViewStore = new MainViewStore();

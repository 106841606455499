import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../store";
import styled from "styled-components";
import { createGlobalStyle } from 'styled-components';

const MainViewComponent: React.FC<{}> = () => {
  const { mainViewStore } = useContext(StoreContext);
  const {
    access_token,
    id_token,
    refresh_token,
    user_data,
    user_data_v1,
    attributes
  } = mainViewStore;

  useEffect(() => {
    console.log('useEffect');
    console.log('fetching');
    mainViewStore.fetchUserData();
    mainViewStore.fetchUserDataV1();
  }, []);

  if (mainViewStore.isLoading || mainViewStore.isLoadingv1) {
    return <div>loading...</div>;
  }

  return (
    <>
      <GlobalStyle />
      <div>
        <StyledHeader>
          <h1>OneAccount reference application v2</h1>
        </StyledHeader>
        <StyledHeaderRight>
          <form action="/logout">
            <input type="submit" value="logout"/>
          </form>
        </StyledHeaderRight>
      </div>

      <hr/>

      <StyledWideContent>
          <h1>Tokens</h1>
          <StyledDataContainer>access_token : <span>{access_token}</span></StyledDataContainer>
          <StyledDataContainer>id_token : <span>{id_token}</span></StyledDataContainer>
          <StyledDataContainer>refresh_token : <span>{refresh_token}</span></StyledDataContainer>
      </StyledWideContent>

      <hr/>

      <div>
          <h1>Userinfo</h1>
          {Object.keys(attributes).map(key => {
          return(
            <div>
              <span>{key}</span> : <span>{attributes[key]}</span>
            </div>
          );
          })
        }
      </div>

      <hr/>

      <div>
          <h1>User's account information from userapi v2 api</h1>
          <div>
            <pre>{user_data}</pre>
          </div>
      </div>

      <div>
          <h1>User's account information from userapi v1 api</h1>
          <div>
            <pre>{user_data_v1}</pre>
          </div>
      </div>

    </>
  );
};

export const MainView = observer(MainViewComponent);

const StyledWideContent = styled('div')`
  width: 100%;
  word-break: break-all;
  word-wrap: break-word;
`;

const StyledHeader = styled('div')`
  float: left;
  width: 80%;
`;

const StyledHeaderRight = styled('div')`
  width: 20%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledDataContainer = styled('div')`
  padding-bottom: 12px;
`;

const GlobalStyle = createGlobalStyle`
    * {
        font-family: monospace;
        font-size: 14px;
    }
`;
